.direct-mail-table .ant-table {
    overflow: auto;
  }
.direct-mail-table {
    table {
        thead {
            color: white;
            background-color: #575757 !important;;

            th {
                font-size: 12px;
                font-weight: 500;
                -webkit-font-smoothing: antialiased;
                -webkit-font-smoothing: subpixel-antialiased;
                font-family: "Roboto-Regular", system-ui, sans-serif;
            }
        }

        tbody td {
            font-size: 12px;
            font-weight: 500;
            -webkit-font-smoothing: antialiased;
            -webkit-font-smoothing: subpixel-antialiased;
            font-family: "Roboto-Regular", system-ui, sans-serif;
        }
    }

    .ant-table-wrapper table
    {
        width: 100%;
        .ant-table-thead >tr>th {
            color: white;
            background-color: #575757;
            font-size: 12px;
            font-weight: 500;
            -webkit-font-smoothing: antialiased;
            -webkit-font-smoothing: subpixel-antialiased;
            font-family: "Roboto-Regular", system-ui, sans-serif;
        }        
    }
    
    .ant-table-container table>thead {
        tr:first-child >*:first-child{
            border-start-start-radius: 0px;
        }
        tr:first-child >*:last-child{
            border-start-end-radius: 0px;
        }
    }
    
    .react-bootstrap-table-pagination {
        .dropdown-toggle {
            background-color: white;
            color: var(--textGray);
            font-size: 10px;
        }

        .react-bootstrap-table-pagination-list {
            .react-bootstrap-table-page-btns-ul {
                font-size: 10px;
    
                li {
                    .page-link {
                        color: var(--brandPrimaryColor);
                    }
    
                    &.active .page-link {
                        color: white;
                        background-color: var(--brandPrimaryColor) !important;
                        border-color: var(--brandPrimaryColor) !important;
                    }
                }
            }
        }
    }

    .react-bootstrap-table-pagination-total {
        font-size: 12px;
    }
}